import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { confirmAlert } from '../utils/react-confirm-alert';
import '../../styles/css/ConfirmAlertCustom.css';
import infoIcon from '../../styles/images/info_icon.png';
import posed from 'react-pose';
import AuthService from '../utils/AuthService';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../../styles/css/Login.css'
import '../../styles/css/main.css'
import PulseLoader from "react-spinners/PulseLoader";
import {getURLParameter, validateEmail, sanitizeEmail} from "../utils/HelpfulFunction";
import { base, db } from '../../base';
const Box = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
});

class Login extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleInfoAndRules = this.toggleInfoAndRules.bind(this);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.handleImageErrored = this.handleImageErrored.bind(this);
        this.toggleSupport = this.toggleSupport.bind(this);
        this.Auth = new AuthService();
        const openPopUp = getURLParameter('openpopup');
        let shouldModalStartOpen = false;
        if(openPopUp === true || openPopUp === "true"){
            shouldModalStartOpen = true
        }
        let userEmail;
        try {
            userEmail = sessionStorage.getItem('userEmail') || false;
        } catch (e) {
            userEmail = false
        }
        this.state = {
            loading: true,
            isVisible: true,
            redirect: false,
            disableText: false,
            name: '',
            email: '',
            zipCode: '',
            modal: shouldModalStartOpen,
            pin_display: false,
            country_code: "1",
            value: "",
            pin: "",
            inputtedEmail: '',
            inputtedName: '',
            inputtedZipCode: '',
            inputtedFullAddress: '',
            inputtedPhoneNumber: '',
            userEmail: userEmail,
        }
    }

    componentDidMount(){
        this.props.loadPage();
    }

    signInWithNumber(){
         this.setState({
             modal: true
         })
    }

    handleImageLoaded() {
      setTimeout(() => this.setState({
        loading: false,
        isVisible: true
      }), 1);
    }

    handleImageErrored() {
      console.log("IMAGE FAILED TO LOAD")
      this.setState({ loading: false });
    }

    handleChange (evt) {
      this.setState({ [evt.target.name]: evt.target.value });
    }

    handleDateChange(event) {
        let value = event.target.value;
        if(event.target.name === "month"){
            if(value > 12){
                value = "12"
            }
        } else if(event.target.name === "day"){
            if(value > 31){
                value = "31"
            }
        } else if(event.target.name === "year"){
            if(value > new Date().getFullYear()){
                value = new Date().getFullYear().toString()
            }
        }
        this.setState({[event.target.name]: value});
    }

    isValidDate(s) {
        const bits = s.split('-');
        const d = new Date(bits[0], bits[1] - 1, bits[2]);
        return d && (d.getMonth() + 1) === parseInt(bits[1]);
    }

    handleBirthdayFocus(){
        this.setState({
            birthdayFocused: true
        }, ()=> {
            document.getElementById('year').placeholder ="YYYY"
            document.getElementById('day').placeholder = "DD"
            document.getElementById('month').placeholder = "MM"
        })
    }

    tryToSignIn(){
      const tenantVariables = this.props.tenantVariables || {};
      const stringConstants = this.props.stringConstants || {};
      const confirmAlertTitleInputError = stringConstants.INPUTERRORTITLE;
      let confirmAlertZipCodeInputError = stringConstants.ZIPCODEMUSTBEFILLEDTEXT;
      const confirmAlertNotOldEnoughInputError = stringConstants.NOTOLDENOUGHTOPLAYOURGAME;
      const confirmAlertNameInputError = stringConstants.NAMEERRORLOGIN;
      const number = this.state.value;
      const country_code = this.state.country_code;
      const zipCode = this.state.zipCode.trim() || "";
      const email = this.state.email.trim();
      const inputtedName = this.state.name.trim();
      const cleanCountryCode = country_code.replace(/\D/g,'');
      const cleanNumber = number.replace(/\D/g,'');
      let inputtedBirthday;
      try {
          inputtedBirthday = sessionStorage.getItem("birthday") || "";
      } catch (e) {
          this.setState({
              modal:false,
          })
          confirmAlert({
              variables: tenantVariables,
              title: stringConstants.ERRORTEXT,
              message: stringConstants.NOCOOKIESTEXT,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              onConfirm: () => {
                  this.setState({
                      modal:true
                  })
              }
          })
          return;
      }

      const year = this.state.year;
      let month = this.state.month;
      let day = this.state.day;

      const allowedAge = tenantVariables.allowedAge || 21;
      if(tenantVariables.collectBirthday && tenantVariables.formBirthday && (!year || !month || !day)){
            confirmAlert({
                variables: tenantVariables,
                title: confirmAlertTitleInputError,
                message: stringConstants.EMPTYLBIRTHDAYERROR,
                confirmLabel: stringConstants.OKTEXT
            })
            return;
      } else if(tenantVariables.collectBirthday && tenantVariables.formBirthday){
          if(year.length < 4){
              confirmAlert({
                  variables: tenantVariables,
                  title: confirmAlertTitleInputError,
                  message: stringConstants.BIRTHDAYYEARBADFORMAT,
                  confirmLabel: stringConstants.OKTEXT
              })
              return;
          }
          if(month && month.length < 2){
              month = "0" + month
          }
          if(day && day.length < 2){
              day = "0" + day
          }
          inputtedBirthday = year + "-" + month + "-" + day;
          if(isNaN(new Date(inputtedBirthday).getTime()) || !this.isValidDate(inputtedBirthday)){
              confirmAlert({
                  variables: tenantVariables,
                  title: confirmAlertTitleInputError,
                  message: stringConstants.INVALIDDATEERROR,
                  confirmLabel: stringConstants.OKTEXT
              })
              return;
          }
          const age = ~~((new Date().getTime() - +new Date(inputtedBirthday)) / (31557600000))
          if (age < allowedAge) {
              confirmAlert({
                  variables: tenantVariables,
                  title: confirmAlertTitleInputError,
                  message: confirmAlertNotOldEnoughInputError,
                  confirmLabel: stringConstants.OKTEXT
              })
              return;
          }
      }
      let collectPromo = this.refs.agree_to_promotion.checked;
      let collectPromoTwo = tenantVariables.promotionCollectionTwo || false;
        if(collectPromoTwo === "false" || collectPromoTwo === "null" || !collectPromoTwo){
            collectPromoTwo = false
        } else {
            collectPromoTwo = this.refs.agree_to_promotion_two.checked
        }
      let collectEmail = tenantVariables.collectEmail;
      if(collectEmail === "false" || collectEmail === "null"){
        collectEmail = false;
      }
      let collectZipCode = tenantVariables.collectZipCode;
      if(collectZipCode === "false" || collectZipCode === "null"){
        collectZipCode = false;
      }
      let allowedZips = tenantVariables.allowedZips || "";
      allowedZips = allowedZips.trim();
      if(collectZipCode && zipCode.length === 0){
        this.setState({
          modal:false,
        })
        confirmAlert({
            variables: tenantVariables,
            title: confirmAlertTitleInputError,
            message: confirmAlertZipCodeInputError,
            cancelLabel: "",
            confirmLabel: stringConstants.OKTEXT,
            onConfirm: () => {
              this.setState({
                modal:true
              })
            }
        })
        return;
      } else if(collectZipCode && !tenantVariables.doNotVerifyAmericanZipCode && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode)){
          this.setState({
              modal:false,
          })
          confirmAlert({
              variables: tenantVariables,
              title: confirmAlertTitleInputError,
              message: stringConstants.NOTVALIDUSZIP,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              onConfirm: () => {
                  this.setState({
                      modal:true
                  })
              }
          })
          return;
      }
      var vm = this;
      if(tenantVariables.collectName && inputtedName === ""){
            confirmAlert({
                variables: tenantVariables,
                title: confirmAlertTitleInputError,
                message: "Please Enter A Name!",
                confirmLabel: stringConstants.OKTEXT
            })
            return;
      } else if(tenantVariables.collectName && (inputtedName === "" || inputtedName.split(' ').length <= 1 || inputtedName.length > 35)){
            confirmAlert({
                variables: tenantVariables,
                title: confirmAlertTitleInputError,
                message: "Must include a last name, and can't be longer than 35 characters!",
                confirmLabel: stringConstants.OKTEXT
            })
            return;
      }
      // if(collectUserName && (name === "" || name.split(' ').length <= 1 || name.length > 35)){
      //   this.setState({
      //     modal:false,
      //   })
      //   confirmAlert({
      //       variables: tenantVariables,
      //       title: confirmAlertTitleInputError,
      //       message: confirmAlertNameInputError,
      //       cancelLabel: "",
      //       confirmLabel: stringConstants.OKTEXT,
      //       onConfirm: () => {
      //         this.setState({
      //           modal:true
      //         })
      //       }
      //   })
      //   return;
      // }
        let yesNoQuestionAnswer = this.state.yesNoQuestionAnswer;
        if(tenantVariables.collectYesNoQuestion && !yesNoQuestionAnswer){
            confirmAlert({
                variables: tenantVariables,
                title: 'Input Error',
                message: "Please select either yes or no",
                confirmLabel: stringConstants.OKTEXT
            })
            return;
        }
      this.setState({
        loading: true,
      })
      this.continue_sigining_in(collectEmail, number, cleanCountryCode, cleanNumber, collectPromo, collectPromoTwo, zipCode, inputtedBirthday, email, vm, inputtedName, stringConstants, yesNoQuestionAnswer)
    }

    anonymousLogin(){
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        const confirmAlertTitleInputError = stringConstants.INPUTERRORTITLE;
        const tenantId = tenantVariables.id;
        if(!this.refs.agree_to_rules_regs.checked){
            this.setState({
                modal:false,
            })
            confirmAlert({
                title: stringConstants.INPUTERRORTITLE,
                message: stringConstants.PLEASEAGREETORULESANDREGS,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            this.props.changeConfirmAlertStyles(this.props.tenantVariables);
            return;
        }
        this.setState({
            loading: true,
        })
        const vm = this;
        this.Auth.anonymousLogin(tenantId).then(res => {
            this.setState({
                loading: false,
            })
            if(res.authToken){
                vm.Auth.setToken(res.authToken);
                vm.props.checkIfAuthenticated();
            }
        })
        .catch(err => {
                this.setState({
                    loading: false,
                })
                confirmAlert({
                    title: confirmAlertTitleInputError,
                    message: stringConstants.OHNOSOMETHINGWENTWRONG,
                    cancelLabel: "",
                    confirmLabel: stringConstants.OKTEXT,
                })
                this.props.changeConfirmAlertStyles(tenantVariables);
            })
    }

    async checkIfGameIsOpenAndHasPrizes(){
        const teamLocation = this.props.teamLocation;
        const currentUrl = 'https://scratchers.sqwadhq.com/' + teamLocation;
        const tenantObject = {'tenantUrl': currentUrl};

        return await this.Auth.fetch(this.Auth.domain + 'services/app/Game/CheckIfExistsAndHasPrizes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tenantObject)
        }).then(res => {
            return res && res.apiStatus === "game_found";
        })
    }

    async handleSubmit(event) {
        event.preventDefault();
        const stringConstants = this.props.stringConstants || {};
        const tenantVariables = this.props.tenantVariables || {};
        if(!this.refs.agree_to_rules_regs.checked){
            this.setState({
                modal:false,
            })
            confirmAlert({
                variables: tenantVariables,
                title: stringConstants.INPUTERRORTITLE,
                message: stringConstants.PLEASEAGREETORULESANDREGS,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            return;
        }
        this.tryToSignIn()
      }

    async continue_sigining_in(collectEmail, number, cleanCountryCode, cleanNumber, collectPromo, collectPromoTwo, inputtedZipCode, inputtedBirthday, inputtedEmail, vm, inputtedName, stringConstants, yesNoQuestionAnswer) {
        var tenantVariables = this.props.tenantVariables || {};
        var confirmAlertNumberInputError = stringConstants.NUMBERANDCOUNTRYBLANK;
        var confirmAlertInvalidNumberInputError = stringConstants.INVALIDNUMBERNOAREACODE;
        var confirmAlertTooManyNumberInputError = stringConstants.INVALIDNUMBERTOMANYNUMBERS;
        var confirmAlertCountryCodeInputError = stringConstants.INVALIDCOUNTRYCODE;
        var somethingWentWrongErrorMessage = stringConstants.OHNOSOMETHINGWENTWRONG;
        var confirmAlertEmailNotBlankInputError = stringConstants.EMAILCANTBEBLANK;
        var confirmAlertNotEmailInputError = stringConstants.NOTANEMAILERROR;
        var confirmAlertTitleInputError = stringConstants.INPUTERRORTITLE;
        var confirmAlertBlockedEmailInputError = stringConstants.INPUTBLOCKEDEMAIL;
        const htmlParameterUserId = getURLParameter("userid");
        let htmlParameterCheckIn = getURLParameter("checkin");
        let base64EncodedEmail = db.collection("users").doc().id;
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        inputtedEmail = inputtedEmail.trim().toLowerCase();
        let htmlParameterEmail = getURLParameter("email");
        if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
            htmlParameterEmail = null
        }
        let sanitizedEmail = "";
        if (inputtedEmail) {
            try {
                sanitizedEmail = sanitizeEmail(inputtedEmail);
            } catch (e) {
                console.log(e)
            }
            base64EncodedEmail = btoa(inputtedEmail);
        } else if (htmlParameterUserId) {
            base64EncodedEmail = htmlParameterUserId;
        } else if (!htmlParameterUserId && isMlbApp) {
            this.setState({
                loading: false
            })
            confirmAlert({
                variables: tenantVariables,
                title: "Login Error",
                message: "Something went wrong please make sure you are logging in via the MLB Ballpark app to play. If you are on the Ballpark app and are seeing this message please close the app and try again.",
                confirmLabel: stringConstants.OKTEXT
            })
            return
        }
        //MLB CHECKIN CHECK
        if (this.props.isMlbTeam && tenantVariables.collectDistance && htmlParameterCheckIn) {
            htmlParameterCheckIn = htmlParameterCheckIn.trim().toLowerCase();
            //necessary because MLB app could pass check-in confirmation as firestore.rules or yes
            const firstCharacter = htmlParameterCheckIn.charAt(0);
            if (firstCharacter !== "y") {
                this.setState({
                    modal: false,
                    loading: false
                })
                const notAcceptableHeader = tenantVariables.notAcceptableLocationHeader || "Hold On";
                const notAcceptableMessage = tenantVariables.notAcceptableLocationMessage || "Please check in on the MLB app before playing!";
                confirmAlert({
                    variables: tenantVariables,
                    title: notAcceptableHeader,
                    message: notAcceptableMessage,
                    cancelLabel: "",
                    confirmLabel: stringConstants.OKTEXT,
                    onConfirm: () => {
                        this.setState({
                            modal: true
                        })
                    }
                })
                return;
            }
        } else if (this.props.isMlbTeam && tenantVariables.collectDistance && !htmlParameterCheckIn) {
            this.setState({
                modal: false,
                loading: false
            })
            const notAcceptableHeader = tenantVariables.notAcceptableLocationHeader || "Hold On";
            const notAcceptableMessage = tenantVariables.notAcceptableLocationMessage || "Please check in on the MLB app before playing!";
            confirmAlert({
                variables: tenantVariables,
                title: notAcceptableHeader,
                message: notAcceptableMessage,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                onConfirm: () => {
                    this.setState({
                        modal: true
                    })
                }
            })
            return;
        }

        const noCollectingEmails = tenantVariables.doNotCollectEmail || isMlbApp;
        if (inputtedEmail === "" && !htmlParameterEmail && !noCollectingEmails) {
            confirmAlert({
                variables: tenantVariables,
                title: 'Missing Info',
                message: "Please Enter An Email!",
                confirmLabel: stringConstants.OKTEXT
            })
            return;
        } else if (!noCollectingEmails && !validateEmail(inputtedEmail) && !htmlParameterEmail) {
            confirmAlert({
                variables: tenantVariables,
                title: 'Missing Info',
                message: "Unable to read email, please check to make sure it is entered correctly",
                confirmLabel: stringConstants.OKTEXT
            })
            return;
        } else if (!noCollectingEmails && (tenantVariables.allowList || tenantVariables.blockList)) {
            const lowerCaseEmail = inputtedEmail.toLowerCase();
            const emailDomain = lowerCaseEmail.split('@')[1];
            let allowListArray = [];
            let blockListArray = [];
            if (tenantVariables.allowList) {
                allowListArray = tenantVariables.allowList.split(" ");
            }
            if (tenantVariables.blockList) {
                blockListArray = tenantVariables.blockList.split(" ");
            }
            if (tenantVariables.allowList && allowListArray.indexOf(lowerCaseEmail) === -1 && allowListArray.indexOf(emailDomain) === -1) {
                confirmAlert({
                    variables: tenantVariables,
                    title: 'Email Error',
                    message: "This Email Address Not Allowed",
                    confirmLabel: stringConstants.OKTEXT
                })
                return;
            } else if (tenantVariables.allowList && (allowListArray.indexOf(lowerCaseEmail) !== -1 || allowListArray.indexOf(emailDomain) === -1)) {
                console.log("Allow override")
            } else if (tenantVariables.blockList && (blockListArray.indexOf(lowerCaseEmail) !== -1 || blockListArray.indexOf(emailDomain) !== -1)) {
                confirmAlert({
                    variables: tenantVariables,
                    title: 'Email Error',
                    message: "This Email Address Not Allowed",
                    confirmLabel: stringConstants.OKTEXT
                })
                return;
            }
        }
            const currentTime = new Date().getTime();
            const userObject = {};
            userObject['lastSignIn'] = currentTime;
            userObject['uid'] = base64EncodedEmail;
            userObject['email'] = inputtedEmail;
            userObject['sanitizedEmail'] = sanitizedEmail;
            if(yesNoQuestionAnswer){
                userObject['yesNoQuestions'] = yesNoQuestionAnswer;
            }
            if(inputtedZipCode){
                userObject['zipCode'] = inputtedZipCode;
            }
            if(inputtedName){
                userObject['name'] = inputtedName;
            }
            // if(inputtedFullAddress){
            //     userObject['address'] = inputtedFullAddress;
            // }
            userObject['optIn'] = collectPromo || false;
            if(inputtedBirthday){
                userObject['birthday'] = inputtedBirthday;
            }
            // if(inputtedPhoneNumber){
            //     userObject['phoneNumber'] = inputtedPhoneNumber;
            // }
            if(tenantVariables.sanitizeEmails && sanitizedEmail && inputtedEmail){
                inputtedEmail = sanitizedEmail;
                base64EncodedEmail = btoa(sanitizedEmail)
                userObject['uid'] = base64EncodedEmail;
            }
          const result = await db.collection('users').doc(base64EncodedEmail).get();
            let userExists = result.data();
            if(!userExists){
                userObject['signUpTime'] = currentTime;
            }
          db.collection('users').doc(base64EncodedEmail).set(userObject, {merge: true})
              .then(() => {
                  if(inputtedEmail){
                      sessionStorage.setItem('userEmail', inputtedEmail);
                  } else {
                      sessionStorage.setItem('userEmail', base64EncodedEmail);
                  }
                  vm.props.setCurrentUser();
                  vm.setState({redirect: true})
          }).catch(err => {
              console.log("addToCollectionError: " + err)
              vm.setState({loading: false})
              alert("Unable to log you in! Check your settings and try again!");
          });
      setTimeout(() => this.setState({ disableText: false }), 5000);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    toggleInfoAndRules(){
        this.setState({
            modalInfoAndRules: !this.state.modalInfoAndRules,
            modal: this.props.isMlbTeam
        });
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport
        });
    }

    render() {
        const { redirect } = this.state;
        const tenantVariables = this.props.tenantVariables || {};
        const tenantRules = this.props.tenantRules || {};
        const stringConstants = this.props.stringConstants || {};
        let htmlParameterEmail = getURLParameter("email");
        const isMlbTeam = this.props.isMlbTeam;
        const verifiedAge = this.props.checkForAgeGate(tenantVariables);

        const collectOptIn = tenantVariables.collectOptIn || false;
        const yesNoQuestionText = tenantVariables.yesNoQuestionText || "";

        // let collectPromoTextTwo = tenantVariables.promotionCollectionTwo;
        // if(collectPromoTextTwo === "false" || collectPromoTextTwo === "null"){
        //     collectPromoTextTwo = false;
        // }
        let collectOptInNotDefaultCheck = tenantVariables.collectOptInNotDefaultCheck;
        if(collectOptInNotDefaultCheck === "false" || collectOptInNotDefaultCheck === "null"){
            collectOptInNotDefaultCheck = false;
        }
        // let collectOptInTwoNotDefaultCheck = tenantVariables.collectOptInTwoNotDefaultCheck;
        // if(collectOptInTwoNotDefaultCheck === "false" || collectOptInTwoNotDefaultCheck === "null"){
        //     collectOptInTwoNotDefaultCheck = false;
        // }
        let collectUserName = tenantVariables.collectName
        if(collectUserName === "false" || collectUserName === "null"){
          collectUserName = false;
        }
        let authRequired = tenantVariables.authRequired;
        if(authRequired === "false" || authRequired === "null"){
            authRequired = false;
        }
        let anonymousLogin = tenantVariables.anonymousLogin;
        if(anonymousLogin === "false" || anonymousLogin === "null"){
            anonymousLogin = false;
        }
        let collectZipCode = tenantVariables.collectZipCode
        if(collectZipCode === "false" || collectZipCode === "null"){
          collectZipCode = false;
        }

        let buttonText = stringConstants.SUBMITTEXT;
        if(this.state.pin.length > 0){
          buttonText = stringConstants.VERIFYTEXT
        }
        let anonymousButtonText = "Let's Play"
        if(!verifiedAge){
          return (
              <Redirect to={"/age_gate"} />
          )
        }

        if (redirect || this.state.userEmail) {
            this.props.setCurrentUser();
            return (
                <Redirect to="/" />
            )
        }
        const htmlParameterUserId = getURLParameter("userid");
        let userIdPassed = false;
        if(htmlParameterUserId || this.props.isMlbTeam){
            userIdPassed = true;
        }
        const phoneNumberButton = stringConstants.GETSTARTEDTEXT;
        let letsStartText = stringConstants.LETSSTARTTEXT;
        const getInfoToStart = stringConstants.WEJUSTNEEDTOGETYOUTEXT;
        const agreeWord = stringConstants.IAGREETOYOUTEXT
        const rulesAndRegs = stringConstants.RULESANDREGSTEXT
        const cancelText = stringConstants.CANCELTEXT
        const pinNumberText = stringConstants.PINNUMBERTEXTEDTOYOUTEXT
        const placeHolderEmailText = stringConstants.PLACEHOLDEREMAILTEXT
        const placeHolderFirstLastText = stringConstants.PLACEHOLDERNAMETEXT
        const placeHolderPhoneText = stringConstants.PLACEHOLDERPHONETEXT
        let placeHolderZipcodeText = stringConstants.PLACEHOLDERZIPCODETEXT;
        const placeHolderBirthdayText = stringConstants.PLACEHOLDERBIRTHDAYTEXT
        const primaryColor = tenantVariables.primaryColor || '#000'
        const secondaryColor = tenantVariables.secondaryColor || '#fff';
        const front_logo = tenantVariables.frontLogoImage || "";
        const topLogo = tenantVariables.topLeftImage || "";
        const link = tenantRules.rulesAndRegsLink;
        const rulesPopUpHeader = tenantRules.rulesPopUpHeader || "Rules & Regs";
        const rulesPopUpText = tenantRules.rulesPopUpText || "";
        let buttonLogOutColor = primaryColor;
        const rulesAndRegsText = tenantRules.rulesAndRegsText || rulesAndRegs ||"";
        const zipCode = this.state.zipCode;
        const agreeToPromotionText = tenantVariables.promotionText || "Agree to receive communications from us";
        const agreeToPromotionTextTwo = tenantVariables.promoTextTwo || "Agree to receive communications from us";
        const noEmailCollection = tenantVariables.doNotCollectEmail || isMlbTeam;
        const mlbPrivacyPolicyLink = tenantVariables.mlbPrivacyPolicyLink || "https://www.mlb.com/app/ballpark/official-information/privacy-policy";
        const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
        const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";


        let labelElement =
                <label className="agree_to_rules_class">
                    <input type="checkbox" ref="agree_to_rules_regs"/> <span onClick={()=>{link?window.open(link, '_blank'):this.toggleInfoAndRules()}}>
                         {rulesAndRegsText}
                    </span>
                </label>

        // let labelElement =
        //     <label className="agree_to_rules_class" htmlFor="group1">
        //         <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
        //             <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
        //         </div>
        //         <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
        //             <label htmlFor="agreeRulesAndRegsId">
        //                 <strong>
        //                     <u>
        //                         <span onClick={()=>{link?window.open(link, '_blank'):this.toggleInfoAndRules()}}>
        //                             {rulesAndRegsText}
        //                         </span>
        //                     </u>
        //                 </strong>
        //             </label>
        //         </div>
        //         {/*<label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{link?window.open(link, '_blank'):this.toggleInfoAndRules()}}>{rulesAndRegsText}</span></u></strong></label>*/}
        //         {/*<input id="group1" type="checkbox" ref="agree_to_rules_regs" /> {agreeWord}*/}
        //         {/*<span onClick={() =>!link ? (this.toggle(), this.toggleInfoAndRules()) : window.open(link, "_blank")}> <u aria-label="Rules & Regs"> {rulesAndRegsText}</u></span>*/}
        //     </label>;

        return (
          <div className="main-container-login" style={{backgroundImage: "url(" + tenantVariables.backgroundImage + ")", color: "white", backgroundColor: "white"}}>
            <div className="loader" style={{display: this.state.loading ? 'block' : 'none' }}>
                <PulseLoader color={primaryColor}/>
              {/*<hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/>*/}
            </div>
            <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="content-container-login">
                <div className="flex-header-home">
                    <div className="header-left">
                        {this.props.isMlbTeam ?
                            <button className="btn btn-logout" onClick={() => { this.toggleSupport() }} style={{color: buttonLogOutColor, borderColor: buttonLogOutColor}}>Support</button>
                            :
                            <img src={topLogo} alt="Logo" id="top-left-logo"/>
                        }
                    </div>
                    {!this.props.isMlbTeam ?
                        <div className="header-right">
                            <button className="btn-info-icon" onClick={() => {this.toggleInfoAndRules()}}><img src={infoIcon} id="info-icon" alt="Info"/></button>
                        </div>
                    :
                        <>
                            <div style={{marginTop: '1em'}}>
                                <button className="btn btn-logout" onClick={() => { window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank") }} style={{color: buttonLogOutColor, borderColor: buttonLogOutColor}}>MLB TOU</button>
                            </div>
                            <div style={{marginTop: '1em'}}>
                                <button className="btn btn-logout" onClick={() => { window.open(mlbPrivacyPolicyLink, "_blank") }} style={{color: buttonLogOutColor, borderColor: buttonLogOutColor}}>MLB Privacy Policy</button>
                            </div>
                        </>
                    }
                </div>
                <div className="hero-text-container" pose={this.state.isVisible ? 'visible' : 'hidden'}>
                    <img src={front_logo} onLoad={this.handleImageLoaded.bind(this)} onError={this.handleImageErrored.bind(this)} className="main-hero-image" id="front-logo-img" alt="Scratch & Win Front Image"/>
                </div>
            </Box>
            <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="button-container-login">
                <div className="button-content-container-number button-item-container">
                    <button id="phoneNumberButton" className="pt-button pt-intent-primary number-button" onClick={() => { this.signInWithNumber() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: 'bold'}}>{phoneNumberButton}</button>
                </div>
            </Box>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="signUpModal">
              <ModalHeader toggle={this.toggle} style={{color: secondaryColor}}>{letsStartText}</ModalHeader>
              <center style={{marginTop:"10px", marginBottom:"10px", display: authRequired || isMlbTeam?"none":""}}>{getInfoToStart}</center>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form onSubmit={this.handleSubmit} id="user-values-form">
                        <div className="input-group" style={{marginBottom:'20px', display: htmlParameterEmail || noEmailCollection ? "none":""}}>
                          <input id="email" name="email" type="email" className="form-control" value={this.state.email} onChange={this.handleChange} placeholder={placeHolderEmailText} />
                        </div>
                        <div className="input-group" style={{marginBottom:'20px', display: tenantVariables.collectPhoneNumber ? '' : 'none'}}>
                          <input style={{width:"13%", flex:"inherit"}} type="string" id="country_code" className="form-control" name="country_code" value={this.state.country_code} onChange={this.handleChange} />
                          <input id="value" name="value" type="tel" className="form-control" value={this.state.value} onChange={this.handleChange} placeholder={placeHolderPhoneText} />
                        </div>
                        <div className="input-group" style={{marginBottom:'20px', display: collectUserName ? '' : 'none'}}>
                          <input id="name" name="name" type="string" className="form-control" value={this.state.name} onChange={this.handleChange} placeholder={placeHolderFirstLastText} />
                        </div>
                        <div className="input-group" style={{marginBottom:'20px', display: collectZipCode ? '' : 'none'}}>
                          <input id="zipCode" name="zipCode" type="string" className="form-control" value={zipCode} onChange={this.handleChange} placeholder={placeHolderZipcodeText} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectBirthday && tenantVariables.formBirthday ? '' : 'none', border: "1px solid #ced4da", borderRadius: "0.25rem", marginTop: 10}}>
                          <input value={this.state.month} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("day").focus()} name="month" id="month" onChange={(e)=> this.handleDateChange(e)} className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} placeholder={placeHolderBirthdayText} maxLength="2" pattern="\d*"/>
                          {this.state.birthdayFocused &&
                              <>
                                  <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                                  <input value={this.state.day} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("year").focus()} name="day" id="day" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onChange={(e)=> this.handleDateChange(e)} onFocus={(e) => this.handleBirthdayFocus()} maxLength="2" pattern="\d*"/>
                                  <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                                  <input value={this.state.year} name="year" id="year" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} maxLength="4" pattern="\d*" onChange={(e)=> this.handleDateChange(e)}/>
                              </>
                          }
                        </div>
                          {tenantVariables.collectYesNoQuestion &&
                              <div className="agree_to_rules_class" style={{textAlign: "left", marginBottom: 20}} onChange={this.handleChange}>
                                  <p className="text-muted2" dangerouslySetInnerHTML={{ __html:yesNoQuestionText}}/>
                                  <input type="radio" value="no" name="yesNoQuestionAnswer" checked={this.state.yesNoQuestionAnswer === "no"}/> <span style={{marginRight:10}}>{stringConstants.NOTEXT}</span>
                                  <input type="radio" value="yes" name="yesNoQuestionAnswer" checked={this.state.yesNoQuestionAnswer === "yes"}/> <span style={{marginRight:10}}>{stringConstants.YESTEXT}</span>
                              </div>
                          }
                        <div style={{textAlign: "left"}}>
                            <div className="checkbox">
                                {labelElement}
                            </div>
                            <div className="checkbox" style={{display: collectOptIn ? '' : 'none'}}>
                                <label className="agree_to_promotion_class">
                                    <input type="checkbox" defaultChecked={!collectOptInNotDefaultCheck && collectOptIn} ref="agree_to_promotion"/> {agreeToPromotionText}
                                </label>
                            </div>
                        </div>
                        {/*<div className="checkbox" style={{padding:'5px', display: collectPromoText && collectPromoTextTwo ? '' : 'none'}}>*/}
                        {/*    <label className="agree_to_promotion_class">*/}
                        {/*        <input type="checkbox" defaultChecked={!collectOptInTwoNotDefaultCheck} ref="agree_to_promotion_two"/> {agreeToPromotionTextTwo}*/}
                        {/*    </label>*/}
                        {/*</div>*/}

                      {/*<div style={{display:collectPromoText?'table-row':"none",textAlign:'left'}}>*/}
                      {/*    <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>*/}
                      {/*        <input type="checkbox" ref="agree_to_promotion" defaultChecked={isSolarBears ? !!collectPromoText : false} style={{width:20,height:20}}/>*/}
                      {/*    </div>*/}
                      {/*    <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>*/}
                      {/*        <label htmlFor="agreeToPromotionId">{agreeToPromotionText}</label>*/}
                      {/*    </div>*/}
                      {/*</div>*/}

                        <div className="form-group" id="pin_part_of_form" style={{display:"none"}}>
                          <label htmlFor="pin">{pinNumberText}</label>
                          <input id="pin" name="pin" type="string" className="form-control" value={this.state.pin} onChange={this.handlePinChange} placeholder="5555"/>
                        </div>
                        <span style={{display: authRequired && !userIdPassed?"none":""}}>
                            <button type='submit' className="btn btn-default btn-admin" id="submitButton" style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}} disabled={this.state.disableText}>{buttonText}</button>
                        </span>
                        <span style={{display: anonymousLogin && !userIdPassed ? "":"none"}}>
                            <button onClick={()=>this.anonymousLogin()} type="button"  className="btn btn-default btn-admin" id="anonymousButton" style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}} disabled={this.state.disableText}>{anonymousButtonText}</button>
                        </span>
                      </form>
                      <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{cancelText}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalInfoAndRules} toggle={this.toggleInfoAndRules} style={{width: '90%'}} id="infoAndRulesModal">
                <ModalHeader toggle={this.toggleInfoAndRules} style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesPopUpHeader}
                    </span>
                </ModalHeader>
                <ModalBody>
                    <div className="container-out">
                        <div className="question-box question-form">
                            <p dangerouslySetInnerHTML={{ __html:rulesPopUpText}}/>
                            {!this.props.isMlbTeam &&
                                <button className="btn btn-default btn-admin" onClick={() => {  window.open(link, '_blank') }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.RULESTEXT}</button>
                            }
                            <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleInfoAndRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.CLOSETEXT}</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
              <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
          </div>
        )
    }
}

export default Login
