import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';
import './styles/css/AdminMain.css';
import './styles/css/SideMenu.css';
import Loading from './components/utils/Loading';
import Home from './components/mainapp/Home';
import AgeVerification from './components/mainapp/AgeVerification';
import RewardViewer from './components/utils/PrizeViewer';
import { base } from './base';
import Login from './components/mainapp/Login';
import { getDistance } from 'geolib';

if(process.env.NODE_ENV === "production"){
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function AuthenticatedRoute({component: Component, variablePath, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
              if(authenticated === true){
                return (
                  <Component {...props} {...rest} />
                )
              } else {
                return (
                  <Redirect to={{pathname: variablePath + '/login', state: {from: props.location}}} />
                )
              }
            }} />
    )
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production" && process.env.REACT_APP_GA_KEY){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}


class App extends Component {
    constructor(props) {
        super(props);
        // this.setTheme = this.setTheme.bind(this);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        const userEmail = sessionStorage.getItem('userEmail') || false;
        // this.setTheme();
        this.state = {
            admin: false,
            authenticated: !!userEmail,
            currentUser: null,
            loading: true,
            tenantVariables: null,
            latitude: null,
            longitude: null
        }
    }

    getLocation(tenantVariables, stringConstants){
      return new Promise((resolve, reject) => {

        if (!navigator.geolocation) {
          reject(stringConstants.GEOLOCATIONNOTSUPPORTED);
        } else {
          const toCheckLatitude = tenantVariables.latitude || 51.525;
          const toCheckLongitude = tenantVariables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                  resolve({distance: getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                          latitude: toCheckLatitude,
                          longitude: toCheckLongitude,
                      }), lat: toCheckLatitude, long: toCheckLongitude})
              },
              (err) => {
                  if(err.message === "User denied Geolocation"){
                    reject(stringConstants.NEEDBROWSERPERMISSION);
                  } else {
                    reject(stringConstants.UNKNOWNERROROCCURED);
                  }
              }
          );
        }
      })
    }

    setCurrentUser() {
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    checkForAgeGate(tenantVariables){
      const variable_age_boolean = tenantVariables.ageBlock || false;
      if(!variable_age_boolean){
        return true
      }
      let variable_age = tenantVariables.ageToVerify || 21;
      variable_age = parseInt(variable_age, 10);
      let user_age = sessionStorage.getItem('verifiedAge') || false;
      if(isNaN(parseInt(user_age))){
        user_age = false
      } else {
        user_age = parseInt(user_age, 10)
      }
      return (user_age && user_age >= variable_age);
    }

    componentDidMount() {
      let language = "english";
      window.history.pushState("",document.title,window.location.pathname+window.location.search);
      this.loadLanguage(language);
      this.tenantRulesRef = base.listenToDoc(`tenantRules/default`, {
          context: this,
          then(data){
              this.setState({
                  tenantRules: data,
                  loading: false
              })
          },
          onFailure(err) {
              console.log("RULES")
              console.log(err)
          }
      });
      this.tenantVariablesRef = base.listenToDoc(`tenantVariables/default`, {
          context: this,
          then(data){
              document.title = data.pageTitle || "";
              this.setState({
                  tenantVariables: data,
                  loading: false
              })
          },
          onFailure(err) {
            console.log("Variables")
            console.log(err)
          }
      })
    }

    componentWillUnmount(){
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.tenantVariablesRef);
    }

    async loadLanguage(language){
      // let languagesConstants = null;
      // const teamLocation = window.location.pathname.split('/')[1] || "";
      // if(teamLocation==="wolfsburgvfl"){
      //     languagesConstants = await import("./constants/languages/de.js");
      // } else if(teamLocation==="baskonia" || teamLocation==="alaves"){
      //     languagesConstants = await import("./constants/languages/esp.js");
      // } else if(teamLocation==="sportamadok2" || teamLocation==="sportamadok1" || teamLocation === "sportexdemo" || teamLocation === "Decathlondemo"){
      //     languagesConstants = await import("./constants/languages/hun.js");
      // } else if(language){
      //     try {
      //         languagesConstants = await import("./constants/languages/"+language+".js");
      //     } catch(e) {
      //         languagesConstants = await import("./constants/languages/en.js");
      //     }
      // } else {
          let languagesConstants = await import("./constants/languages/en.js");
      // }
      this.setState({
        lanaguageConstants: languagesConstants
      })
    }

  render() {
    const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
    return (
      <div style={{ margin: "0 auto" }}>
        <BrowserRouter>
          <div className="main-content">
              <div className="workspace">
                <Switch>
                    <Route
                        exact
                        path="/login"
                        render={(props) => {
                            return <Login authenticated={this.state.authenticated} loadPage={() => logPageView()} setCurrentUser={this.setCurrentUser} tenantVariables={this.state.tenantVariables} tenantRules={this.state.tenantRules} checkForAgeGate={this.checkForAgeGate} isMlbTeam={isMlbApp} stringConstants = {this.state.lanaguageConstants} {...props} />
                        }}
                    />

                    <AuthenticatedRoute
                        exact
                        path="/"
                        authenticated={this.state.authenticated}
                        tenantVariables={this.state.tenantVariables}
                        tenantRules={this.state.tenantRules}
                        passedEmail={this.state.passedEmail}
                        isMlbTeam={isMlbApp}
                        stringConstants={this.state.lanaguageConstants}
                        loadPage={() => logPageView()}
                        checkForAgeGate={this.checkForAgeGate}
                        component={Home}
                        getLocation={this.getLocation}
                    />

                    {/*<Route*/}
                    {/*    exact*/}
                    {/*    path="/"*/}
                    {/*    render={(props) => {*/}
                    {/*        return <Home tenantVariables={this.state.tenantVariables} tenantRules={this.state.tenantRules} passedEmail={this.state.passedEmail} checkForAgeGate={this.checkForAgeGate} isMlbTeam={isMlbApp} stringConstants = {this.state.lanaguageConstants} {...props} />*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Route
                        exact
                        path="/age_gate"
                        render={(props) => {
                            return <AgeVerification loadPage={() => logPageView()} tenantVariables={this.state.tenantVariables} tenantRules={this.state.tenantRules} checkForAgeGate={this.checkForAgeGate} isMlbTeam={isMlbApp} stringConstants = {this.state.lanaguageConstants} {...props} />
                        }}
                    />

                    <Route
                        exact
                        path="/prizeviewer"
                        render={(props) => {
                            return <RewardViewer loadPage={() => logPageView()} {...props} />
                        }}
                    />

                  <Redirect to={`/login`}/>
                </Switch>
              </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
