import React, { Component } from 'react';
import '../../styles/css/main.css'
import PulseLoader from "react-spinners/PulseLoader";

class Loading extends Component {

  render() {
    var primaryColor = '#000'

    if (this.props.loading === true) {
            return (
              <div className="main-container-login" style={{backgroundColor:"white"}}>
                <div className="loader">
                    <PulseLoader color={primaryColor}/>
                </div>
              </div>
            )
        }
    }
}

export default Loading;
